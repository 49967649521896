import React, { useEffect, useState } from 'react';
import { Box, Heading } from 'theme-ui';
import Fade from 'react-reveal/Fade';
import CareerModal from './CareerModal';
import Timeline from './Timeline';

export default function CareerSection1() {
  // ? ===========================
  // ? =======   State   =========
  // ? ===========================

  // ? ===========================
  // ? ======   Effects   ========
  // ? ===========================

  // ? ===========================
  // ? ========   JSX   =========
  // ? ===========================

  return (
    <>
      <Box sx={styles.container} id='careerSection1'>
        <Box sx={styles.textContainer}>
          <Fade left>
            <Heading as='h2' sx={styles.heading}>
              Driven, Motivated and Ready
            </Heading>
          </Fade>
        </Box>

        <Timeline />
      </Box>
    </>
  );
}

// ? ===========================
// ? =======   Styles   ========
// ? ===========================

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    animationDuration: '2s',
    animationIterationCount: '1',
    animationTimingFunction: 'linear',
    WebkitAnimationFillMode: 'forwards',
    position: 'relative',
  },

  textContainer: {
    width: '100%',
  },
  heading: {
    fontWeight: '700',
    fontSize: ['3.25rem', '3rem', '4rem', '6rem'],
    margin: '6rem 0rem',
    textAlign: 'center',
    width: '100%',
    borderColor: 'primary',
  },
};
