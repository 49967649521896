import React, { useContext } from 'react';
import { Box, Heading, Image } from 'theme-ui';
import { FaLocationArrow, FaUser } from 'react-icons/fa';
import calculateEmployedLength from './calculateEmployedLength';
import { store } from '../../Context/Store';
export default function CareerModal({ modalData, setModalData }) {
  const { globalState, dispatch } = useContext(store);

  return (
    <Box sx={styles.modalContent}>
      <Image sx={styles.logo} src={modalData.logo.file.url} />
      <Heading as='h2'>{modalData.company}</Heading>
      <Heading as='h3'>
        <FaUser /> {modalData.jobTitle}
      </Heading>
      <Heading as='h4'>
        <FaLocationArrow /> {modalData.location}
      </Heading>
      <Heading as='h5'>
        {modalData.dateStartedFormatted} -{' '}
        {modalData.presentlyEmployed
          ? 'Present'
          : modalData.dateFinishedFormatted}
      </Heading>
      <Heading as='h6'>
        {calculateEmployedLength(
          modalData.dateStarted,
          modalData.presentlyEmployed
            ? new Date().toISOString().split('T')[0]
            : modalData.dateFinished
        )}
      </Heading>

      <Box
        sx={styles.bodyContent}
        dangerouslySetInnerHTML={{
          __html: modalData?.description?.childrenMarkdownRemark?.[0].html,
        }}
      />
    </Box>
  );
}

const styles = {
  modalContent: {
    width: '100%',
    fontSize: ['0.8rem', '0.9rem', '1rem'],
    h2: {
      fontSize: '2.5em',
      marginBottom: '1.5rem',
      borderBottom: '2px solid',
      width: 'fit-content',
      paddingBottom: '1rem',
    },
    h3: {
      fontSize: '1.5em',
      marginBottom: '1rem',
      color: 'primary',
    },
    h4: {
      fontSize: '1.1em',
      marginBottom: '1rem',
      color: 'grey',
    },

    h5: {
      fontSize: '1em',
      marginBottom: '0.25rem',
      color: 'primary',
    },

    h6: {
      fontSize: '0.8em',
      marginBottom: '1rem',
    },
  },
  logo: {
    maxHeight: ['60px', '', '100px'],
    float: 'right',
    marginBottom: '2rem',
    marginLeft: '2rem',
  },

  bodyContent: {
    borderTop: '2px solid',
    marginTop: '2rem',
    paddingTop: '1rem',
  },
};
