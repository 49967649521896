import React from 'react'
import { Box, Heading } from 'theme-ui'
import CareerHero from '../components/CareerSections/CareerHero'
import CareerSection1 from '../components/CareerSections/CareerSection1'
import TechStack from '../components/EngineeringSections/TechStack'
export default function Career() {
  return (
    <Box as='main' sx={styles.container}>
      <CareerHero />
      <CareerSection1 />
      <Heading sx={styles.heading}>The Stack</Heading>
      <TechStack
        title='The Frontend Stack'
        subtitle={'The current most recent frontend technologies I am using'}
        filterByType={['Frontend', 'Full Stack']}
      />
      <TechStack
        title='The Backend Stack'
        subtitle={'The current most recent backend technologies I am using'}
        filterByType={['Full Stack', 'Backend']}
        depreciated={false}
      />
      <TechStack
        title='Operation Tools Stack'
        subtitle={'Up to date and actively using these operation tools'}
        filterByType={['DevOps']}
        depreciated={false}
      />
      <TechStack
        title='The Workflow Stack'
        subtitle={'The things I need to structure my day'}
        filterByType={['Workflow']}
        depreciated={false}
      />
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  heading: {
    fontWeight: '700',
    fontSize: ['3.25rem', '3rem', '4rem', '6rem'],
    margin: '6rem 0rem',
    textAlign: 'center',
    width: '100%',
    borderColor: 'primary',
  },
}
