import React, { useState, useContext } from 'react';
import { Box, Heading, Image, Button } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import {
  FaLocationArrow,
  FaUser,
  FaArrowAltCircleRight,
  FaArrowAltCircleLeft,
} from 'react-icons/fa';
import calculateEmployedLength from './calculateEmployedLength';
import CareerModal from './CareerModal';
import { store } from '../../Context/Store';

export default function Timeline() {
  const { globalState, dispatch } = useContext(store);

  // ? ===========================
  // ? ========   Data   =========
  // ? ===========================

  const data = useStaticQuery(graphql`
    query careerQuery {
      allContentfulCareerHistory(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: dateStarted, order: DESC }
      ) {
        edges {
          node {
            presentlyEmployed
            location
            jobTitle
            id
            description {
              childrenMarkdownRemark {
                html
              }
            }
            dateStarted
            dateStartedFormatted: dateStarted(formatString: "MMM YYYY")
            dateFinished
            dateFinishedFormatted: dateFinished(formatString: "MMM YYYY")
            company
            logo {
              file {
                url
              }
              description
            }
            contentful_id
          }
        }
      }
    }
  `);

  const careerHistoryData = data.allContentfulCareerHistory.edges;
  const numberOfCareers = careerHistoryData.length;

  // ? ===========================
  // ? =======   State   =========
  // ? ===========================

  const [currentPosition, setCurrentPosition] = useState(0);

  // ? ===========================
  // ? =====   functions   =======
  // ? ===========================

  const handlePosition = (direction) => {
    if (direction === 'forward') {
      if (currentPosition < numberOfCareers - 1) {
        setCurrentPosition(currentPosition + 1);
      }
    } else {
      if (currentPosition > 0) {
        setCurrentPosition(currentPosition - 1);
      }
    }
  };

  // ? ===========================
  // ? ======   Effects   ========
  // ? ===========================

  const currentPositionStyle = {
    marginLeft: `-${currentPosition * 100}vw`,
  };

  return (
    <Box sx={styles.outerContainer}>
      <Box sx={{ ...styles.careerTimeline1, ...currentPositionStyle }}>
        {careerHistoryData.map(({ node }) => {
          return (
            <Box key={node.id} sx={styles.careerLocation}>
              <Box sx={styles.jobDetails}>
                <Image src={node.logo.file.url} />
                <Box sx={styles.imageBorder} />
                <Heading as='h4'>{node.company}</Heading>
                <Heading as='h5'>
                  <FaUser /> {node.jobTitle}
                </Heading>
                <Heading as='h6'>
                  <FaLocationArrow /> {node.location}
                </Heading>

                <Button
                  onClick={() => {
                    return dispatch({
                      type: 'OPEN_MODAL',
                      children: <CareerModal modalData={node} />,
                    });
                  }}
                >
                  Read More <FaArrowAltCircleRight />
                </Button>
              </Box>
              <Box sx={styles.jobDates}>
                <Heading as='h4'>
                  {node.dateStartedFormatted} -{' '}
                  {node.presentlyEmployed
                    ? 'Present'
                    : node.dateFinishedFormatted}
                </Heading>
                <Heading as='h5'>
                  {calculateEmployedLength(
                    node.dateStarted,
                    node.presentlyEmployed
                      ? new Date().toISOString().split('T')[0]
                      : node.dateFinished
                  )}
                </Heading>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={styles.leftArrow}>
        <FaArrowAltCircleLeft
          style={{ opacity: currentPosition === 0 ? '0.5' : '1' }}
          onClick={() => handlePosition('back')}
        />
      </Box>
      <Box sx={styles.rightArrow}>
        <FaArrowAltCircleRight
          style={{
            opacity: currentPosition === numberOfCareers - 1 ? '0.5' : '1',
          }}
          onClick={() => handlePosition('forward')}
        />
      </Box>
    </Box>
  );
}

const styles = {
  outerContainer: {
    height: '100vh',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  careerTimeline1: {
    width: '100%',
    whiteSpace: 'nowrap',
    // overflow: 'scroll',
    display: 'flex',
    position: 'relative',
    margin: '0 0 0 auto',
    transition: 'all ease-in-out 0.9s',
    ' > div:nth-of-type(1)': {
      '::after': {
        content: "''",
        height: '5px',
        width: '50vw',
        backgroundColor: 'lightgrey',
        position: 'absolute',
        top: '70%',
        left: '50vw',
        transform: 'translateY(-50%)',
        borderRadius: '500px 0px 0px 500px',
      },
    },
    ' > :last-child': {
      '::after': {
        content: "''",
        height: '5px',
        width: '50vw',
        backgroundColor: 'lightgrey',
        position: 'absolute',
        top: '70%',
        left: '0vw',
        transform: 'translateY(-50%)',
        borderRadius: '500px 0px 0px 500px',
      },
    },
  },
  careerLocation: {
    height: 'calc(100vh + 86px)',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '::before': {
      content: "''",
      height: '25px',
      width: '25px',
      borderRadius: '500px',
      backgroundColor: 'primary',
      border: 'solid 3px',
      borderColor: 'lightgrey',
      zIndex: '10',
      position: 'absolute',
      top: '70%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '500px',
    },

    '::after': {
      content: "''",
      height: '5px',
      width: '100vw',
      backgroundColor: 'lightgrey',
      position: 'absolute',
      top: '70%',
      left: '0%',
      transform: 'translateY(-50%)',
    },
  },
  imageBorder: {
    backgroundColor: 'lightgrey',
    margin: '0.5rem auto 0.5rem',
    height: '30px',
    width: '2px',
    borderRadius: '10px',
  },
  jobDetails: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '65%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
    padding: ['1rem', '', '2rem'],
    height: ['310px', '400px', '', ''],
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: 'solid 1px lightgrey',
    borderRadius: '20px',
    boxShadow: '2px 2px 10px lightgrey',
    fontSize: ['0.7rem', '0.8rem', '0.9rem', '1rem'],
    width: ['90%', '', '', 'auto'],
    whiteSpace: 'normal',
    img: {
      height: ['40px', '50px', '75px'],
      width: 'auto',
    },
    h4: {
      fontSize: '2em',
      marginBottom: '1rem',
      flexGrow: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    h5: {
      fontSize: '1.5em',
      marginBottom: '1rem',
      color: 'primary',
    },
    h6: {
      fontSize: '1em',
      marginBottom: '1rem',
      color: '#888c8a',
    },

    button: {
      borderColor: 'secondary',
      textAlign: 'center',
      padding: '0rem',
      margin: '2rem auto 0rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100px',
      backgroundColor: 'secondary',
      color: 'white',
      padding: '0.5rem 1rem',
      width: 'fit-content',
      cursor: 'pointer',
      svg: {
        margin: '0rem 0rem 0rem 0.5rem',
      },
      ':hover': {
        color: 'secondary',
      },
    },
  },
  jobDates: {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    padding: '1.5rem',
    fontSize: ['1rem', '1.1rem', '1.2rem'],
    h4: {
      fontSize: '1em',
      color: 'primary',
    },
    h5: {
      fontSize: '0.75em',
      textAlign: 'center',
      opacity: '0.7',
    },
  },
  leftArrow: {
    position: 'absolute',
    top: '50%',
    left: ['0rem', '', '', '1rem'],
    transform: 'translateY(-50%)',
    svg: {
      margin: ['0.25rem', '', '1rem'],
      fontSize: '2rem',
      color: 'primary',
    },
  },

  rightArrow: {
    position: 'absolute',
    top: '50%',
    right: ['0rem', '', '', '1rem'],
    transform: 'translateY(-50%)',
    svg: {
      margin: ['0.25rem', '', '1rem'],
      fontSize: '2rem',
      color: 'primary',
    },
  },
};
