import React from 'react';
import { Box, Heading } from 'theme-ui';
import Fade from 'react-reveal/Fade';
import { keyframes } from '@emotion/react';
import { IoIosArrowDropdown } from 'react-icons/io';

export default function CareerHero() {
  return (
    <Box sx={styles.container}>
      <Box>
        <Fade>
          <Heading as='h1' sx={styles.heading}>
            Where The Journey Began.
          </Heading>
        </Fade>
        <Box
          sx={styles.arrowDown}
          onClick={() => {
            const nextSection = document?.getElementById('careerSection1');
            if (nextSection) {
              nextSection.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
              });
            }
          }}
        >
          <Fade>
            <IoIosArrowDropdown />
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}

const shorten = keyframes`
  from {
    height:200vh
  }
  to {
    height:110vh
  }
`;

const cursor = keyframes`
  50% {
    border-color:black;
  }
  50.0000001% {
    border-color:#eaeaea;
  }
`;

const styles = {
  container: {
    padding: '5rem 1rem',
    height: '100vh',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    animationName: `${shorten}`,
    animationDuration: '2s',
    animationIterationCount: '1',
    animationTimingFunction: 'linear',
    WebkitAnimationFillMode: 'forwards',
  },

  heading: {
    fontWeight: '700',
    fontSize: ['3rem', '4rem', '6rem', '8rem'],
    marginBottom: '2rem',
    background:
      'linear-gradient(125deg, rgba(0,110,130,1) 0%, rgba(201,128,0,1) 25%, rgba(239,86,2,1) 50%, rgba(182,44,49,1) 75%, rgba(79,83,129,1) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    borderRight: 'solid 1px',
    animation: `${cursor} 1s linear infinite`,
  },
  arrowDown: {
    fontSize: '3rem',
    position: 'absolute',
    bottom: 'calc(15vh + 2rem)',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'primary',
    svg: {
      transition: 'all ease-in-out 0.7s',
      borderRadius: '500px',
      ':hover': {
        backgroundColor: 'primary',
        color: 'white',
      },
    },
  },
};
