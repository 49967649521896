import dayjs from 'dayjs';

export default (startDate, endDate) => {
  // format of date should be yyyy-mm-dd
  const startDateParsed = dayjs(startDate.slice(0, -3));
  const endDateParsed = dayjs(endDate.slice(0, -3));

  const timeDifference = endDateParsed.diff(startDateParsed, 'year', true);

  var integerPart = parseInt(timeDifference);
  var decimalPart = timeDifference - integerPart;

  const months =
    12 * decimalPart ? `${Math.floor(12 * decimalPart)} Months` : '';

  const years =
    Math.floor(timeDifference) !== 0
      ? `${Math.floor(timeDifference)} Years`
      : '';

  const bothYearsAndMonths = years && months ? ', ' : '';

  return `(${years}${bothYearsAndMonths}${months})`;
};
