import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import LazyLoad from 'react-lazyload';
import {
  Box,
  Image,
  Heading,
  Link as ExternalLink,
  Text,
  Button,
} from 'theme-ui';
import shortid from 'shortid';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { store } from '../../Context/Store';
// ? =================================
// ? ====  Additional Components  ====
// ? =================================

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <FaChevronRight {...props} />
);

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <FaChevronLeft {...props} />
);

// ? =============================
// ? ====  Default Component  ====
// ? =============================

export default function FrontendTechStack({
  title = 'Tech Stack',
  subtitle = 'The Ever Growing Stack',
  text,
  settings,
  defaultSettings,
  filterByType = ['Frontend', 'Full Stack', 'Design'],
  depreciated = false,
}) {
  // ? ===========================
  // ? ========   State   =========
  // ? ===========================

  const { globalState, dispatch } = useContext(store);

  // ? ================
  // ? ===== Data =====
  // ? ================

  const data = useStaticQuery(graphql`
    query FrontendTechStackEngineer {
      allContentfulStack(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            id
            logo {
              fixed {
                src
              }
              title
            }
            website
            depreciated
            name
            type
          }
        }
      }
    }
  `);

  const logoList = data.allContentfulStack.edges;

  const filterFrontEndLogos = logoList.filter(({ node }) => {
    const findTypes = filterByType.map((type) => type === node.type); // finds if any of the types from props are true
    return (
      node.depreciated === depreciated && findTypes.some((element) => element) // if it matches depreaciated and there is a type that match
    );
  });

  // ? ================
  // ? === Carousel ===
  // ? ================

  const settingCombined = {
    ...defaultSettings,
    ...settings,
  }; // combines the settings allows settings to be overwritten rather than needed to write entire settings again

  const modalContent = (
    <Box sx={styles.modalContent}>
      <Heading as='h3' sx={styles.title}>
        {title}
      </Heading>
      <Heading as='h4' sx={styles.subtitle}>
        {subtitle}
      </Heading>
      {text && <Text as='p'>{text}</Text>}
      <Box sx={styles.stackList}>
        {filterFrontEndLogos.map(({ node }) => {
          return (
            <Box sx={styles.stackContainer} key={shortid.generate()}>
              <Box sx={styles.logoContainer}>
                <Image
                  sx={styles.modalLogo}
                  src={node.logo.fixed.src}
                  alt={`${node.logo.title}logo`}
                />
              </Box>
              <Text as='p' sx={styles.modalStackText}>
                {node.name}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <LazyLoad>
      <Box sx={styles.container}>
        <Box sx={styles.textContainer}>
          <Fade>
            <>
              <Heading as='h3' sx={styles.title}>
                {title}
              </Heading>

              <Heading as='h4' sx={styles.subtitle}>
                {subtitle}
              </Heading>

              {text && <Text as='p'>{text}</Text>}
            </>
          </Fade>
        </Box>

        <Box sx={styles.carouselContainer}>
          <Fade>
            <Slider {...settingCombined}>
              {filterFrontEndLogos.map(({ node }) => {
                return (
                  <ExternalLink
                    href={node.website}
                    target='_blank'
                    key={shortid.generate()}
                    rel='noopener'
                  >
                    <Image
                      sx={styles.logo}
                      src={node.logo.fixed.src}
                      alt={`${node.logo.title}logo`}
                    />
                  </ExternalLink>
                );
              })}
            </Slider>
          </Fade>
        </Box>

        <Button
          sx={styles.button}
          onClick={() =>
            dispatch({
              type: 'OPEN_MODAL',
              children: modalContent,
            })
          }
        >
          View List
        </Button>
      </Box>
    </LazyLoad>
  );
}

// ? ================
// ? ==== Styles ====
// ? ================

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: ['2rem 1rem 2rem', '', '2rem 2rem 2rem'],
    flexDirection: 'column',
  },
  textContainer: {
    padding: ['0rem', '', '0rem 1rem'],
  },
  title: {
    fontSize: '2rem',
    marginBottom: '1rem',
    color: 'primary',
  },
  subtitle: {
    fontSize: '1.25rem',
    opacity: '0.5',
  },
  text: {},

  carouselContainer: {
    width: '100%',
    margin: '0 0 0 auto',
    borderRadius: '10px 0px 0px 10px',
    padding: '1rem 0rem 2rem',
    '.slick-arrow': {
      color: 'primary',
      zIndex: '1',
      padding: '0.25rem',
      backgroundColor: 'dark',
      height: '2rem',
      width: '2rem',
      transform: 'unset',
    },
    '.slick-prev': {
      left: 'unset',
      top: 'unset',
      bottom: '-3rem',
      right: '2rem',
    },
    '.slick-next': {
      top: 'unset',
      bottom: '-3rem',
      right: '0rem',
    },
    '.slick-dots': {
      display: 'flex !important',
      flexDirection: 'column',
      bottom: '0rem',
      left: '0rem',
      li: {
        height: '30px',
        width: '30px',

        button: {
          '::before': {
            color: 'primary',
            fontSize: '10px',
          },
        },
      },
      'li.slick-active': {
        color: 'primary',
      },
    },
    '.slick-slide > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      height: ['30px', '35px', '40px'],
      display: 'flex',
      margin: '1rem',
    },
  },

  logo: {
    height: '40px',
    margin: '0.5rem',
  },

  button: {
    position: ['relative'],
    top: ['-1rem'],
    left: ['', '', '1rem'],
  },
  // ? ===========================
  // ? ======  Modal Styles  =====
  // ? ===========================

  modalContent: {
    width: '100%',
  },

  stackList: {
    marginTop: '2rem',
  },

  stackContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'solid 1px lightgrey',
    padding: '0.75rem',
  },
  logoContainer: {
    width: '150px',
    maxWidth: '100px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  modalLogo: {
    maxHeight: ['35px', '40px'],
  },

  modalStackText: {
    minWidth: '100px',
    paddingLeft: ['1rem', '2rem', '3rem'],
    display: 'inline',
  },
};

// ? =======================
// ? ==== Default Props ====
// ? =======================

const defaultSettingsProp = {
  dots: false,
  fade: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  // slidesToShow: 7,
  slidesToScroll: 2,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  variableWidth: true,
};

FrontendTechStack.defaultProps = {
  settings: defaultSettingsProp,
  defaultSettings: defaultSettingsProp,
  images: [
    'https://res.cloudinary.com/dpakxm3nm/image/upload/v1623197451/portfolio/placeholder_h66nrb.png',
    'https://res.cloudinary.com/dpakxm3nm/image/upload/v1623197451/portfolio/placeholder_h66nrb.png',
    'https://res.cloudinary.com/dpakxm3nm/image/upload/v1623197451/portfolio/placeholder_h66nrb.png',
  ],
};
